import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Sidan saknas</h1>
      <p>Oj, tyvärr finns inte den här sidan.</p>
    </div>
  </Layout>
)

export default NotFoundPage
